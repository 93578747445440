import { Component, OnInit } from '@angular/core';
import { Poems } from './poems';
import { FirebaseService } from './lib/services/firebase.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public poems: Poem[] = [];
  public data: Poem[];
  public poemsLoaded: boolean = false;
  public currentTitle: string;

  public constructor() {
    this.updatePoems();
  }

  private updatePoems() {
    // var result: firebase.functions.HttpsCallableResult = Poems.json;
    var functions = FirebaseService.instance.functions.httpsCallable('allPoems');
    functions().then(result => {
      this.poems = result.data.data;
      this.poems[0].select = true;
      this.poemsLoaded = true;
      this.currentTitle = this.poems[0].title;
    }).catch(function (error) {
      // Getting the Error details.
      var code = error.code;
      var message = error.message;
      var details = error.details;
      console.log(code + ' ' + message + ' ' + details);
    });
  }

  private updatePoems1() {
    let lineNumber = 0;

    let poem: Poem;
    let content: string;

    while (Poems.get[lineNumber] != undefined) {
      let line = Poems.get[lineNumber++];
      let select = false;

      if (line.startsWith('title:')) {
        if (this.poems.length == 0) {
          select = true;
        } else {
          poem.content = content;
        }
        content = '';
        poem = new Poem(this.poems.length + '', line.replace('title:', ''), '', select);
        this.poems.push(poem);
      } else content = content.concat(line, '\n');
    }

    poem.content = content;
  }

  public titleClick(poem: Poem) {
    this.poems.forEach(value => value.select = false);
    poem.select = true;
    document.getElementById(poem.id).scrollIntoView();
  }

  private isScrolledIntoView() {
    let index = document.getElementById('index');
    let indexContainers = document.getElementsByClassName('index_container');
    let top = document.getElementById('scroll_content').scrollTop + 20;

    for (var i = 0; i < this.poems.length; ++i) {
      let elTop = document.getElementById(this.poems[i].id).offsetHeight;
      for (var j = 0; j < i; ++j)
        elTop += document.getElementById(this.poems[j].id).offsetHeight;
      if (top < elTop) {
        this.poems[i].select = true;
        if (this.currentTitle != this.poems[i].title) {
          document.getElementById('fixed_title_text').style.transitionDuration = '0s';
          document.getElementById('fixed_title_text').style.transform = 'translate(-100%)';
          setTimeout(() => {
            this.currentTitle = this.poems[i].title;
            document.getElementById('fixed_title_text').style.transitionDuration = '1.5s';
            document.getElementById('fixed_title_text').style.transform = 'translate(0)';
          }, 1);
        }
        for (var k = 0; k < this.poems.length; ++k) {
          if (k != i) this.poems[k].select = false;
        }

        let selectedTop = (indexContainers[i] as HTMLElement).offsetTop - 98;
        index.scrollTop = selectedTop;

        break;
      }
    }

    document.getElementById('title_show_icon').style.top = top + 20 + 'px';
  }

  private titlesVisible = false;
  public showTitles() {
    if (!this.titlesVisible) {
      document.getElementById('index').style.minWidth = '75%';
      this.titlesVisible = true;
    } else {
      document.getElementById('index').style.minWidth = '0';
      this.titlesVisible = false;
    }
  }

  public ngOnInit(): void {
    document.getElementById('scroll_content').addEventListener('scroll', () => this.isScrolledIntoView());
  }

}

class Poem {
  constructor(public id: string, public title: string, public content: string, public select?: boolean) { }
}